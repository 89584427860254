import { createContext, useState, useContext, useEffect } from "react";
import { client } from "../client";

const CmsDataContext = createContext(null);

export const CmsDataProvider = ({ children }) => {
    const [teamData, setTeamData] = useState(null);
    const [projectsData, setProjectsData] = useState(null);
    const [citiesData, setCitiesData] = useState(null);

    const fetchTeamData = () => {
        const query = `*[_type == "theTeam"]`;
        client.fetch(query).then((result) => setTeamData(result));
    };

    const fetchProjectsData = () => {
        const query = `*[_type == "portfolio"]`;
        client.fetch(query).then((result) => setProjectsData(result));
    };

    const fetchCitiesData = () => {
        const query = `*[_type == "city"]`;
        client.fetch(query).then((result) => setCitiesData(result));
    };

    useEffect(() => {
        fetchTeamData();
        fetchProjectsData();
        fetchCitiesData();
    }, []);

    return <CmsDataContext.Provider value={{ teamData, projectsData, citiesData }}>{children}</CmsDataContext.Provider>;
};

export const useCmsDataContext = () => {
    const ctx = useContext(CmsDataContext);
    if (!ctx) {
        throw new Error("Not wrapped");
    }
    return ctx;
};
