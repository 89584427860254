import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";
import style from "./ImageSlider.module.css";
import ImageUrlBuilder from "@sanity/image-url";
import { client } from "../../../../client";
import { WheelControls } from "../../../WheelControls";

export const ImageSlider = (props) => {
    const imageBuilder = ImageUrlBuilder(client);

    const [sliderRef] = useKeenSlider(
        {
            loop: true,
            slides: {
                origin: "center",
                perView: 1.2,
                spacing: 15,
            },
            defaultAnimation: { duration: 2000 },
        },
        [
            WheelControls,
            (slider) => {
                let timeout;
                let mouseOver = false;
                function clearNextTimeout() {
                    clearTimeout(timeout);
                }
                function nextTimeout() {
                    clearTimeout(timeout);
                    if (mouseOver) return;
                    timeout = setTimeout(() => {
                        slider.next();
                    }, 3000);
                }
                slider.on("created", () => {
                    slider.container.addEventListener("mouseover", () => {
                        mouseOver = true;
                        clearNextTimeout();
                    });
                    slider.container.addEventListener("mouseout", () => {
                        mouseOver = false;
                        nextTimeout();
                    });
                    nextTimeout();
                });
                slider.on("dragStarted", clearNextTimeout);
                slider.on("animationEnded", nextTimeout);
                slider.on("updated", nextTimeout);
            },
        ]
    );

    return (
        <div ref={sliderRef} className={`keen-slider ${style.moduleWrapper}`}>
            {props.content.images.map((image) => {
                return (
                    <div
                        className={`keen-slider__slide ${style.image}`}
                        style={{ backgroundImage: `url(${imageBuilder.image(image.image)})` }}
                    ></div>
                );
            })}
        </div>
    );
};
