import ImageUrlBuilder from "@sanity/image-url";
import clsx from "clsx";
import { useState } from "react";
import { client } from "../../client";
import style from "./MemberLabel.module.css";

export const MemberLabel = ({ memberData }) => {
    const imageBuilder = ImageUrlBuilder(client);
    const [showPopup, setShowPopup] = useState(false);

    const handleMemberClick = (e) => {
        if (document.querySelector(`.${style.show}`)) {
            document.querySelector(`.${style.show}`).classList.remove(`${style.show}`);
        }
        setShowPopup((showPopup) => !showPopup);
    };

    return (
        <>
            <div className={style.moduleWrapper} onClick={handleMemberClick}>
                {memberData && (
                    <>
                        <div className={style.imgWrapper}>
                            <div
                                className={style.img}
                                style={{ backgroundImage: `url(${imageBuilder.image(memberData.team.image)})` }}
                            ></div>
                        </div>
                        <div className={style.name}>{memberData.team.name}</div>
                        <div className={style.position}>{memberData.team.position}</div>
                    </>
                )}
            </div>
            <div className={`${style.overlay} ${clsx(showPopup && style.show)}`}>
                <div
                    className={style.imgWrapper}
                    style={{ backgroundImage: `url(${imageBuilder.image(memberData.team.image)})` }}
                ></div>
                <div className={style.bioWrapper}>
                    <div className={style.close} onClick={() => setShowPopup(false)}></div>
                    <div className={style.descWrapper}>
                        <div className={style.nameWrapper}>
                            <div className={style.name}>{memberData.team.name}</div>
                            <div className={style.position}>{memberData.team.position}</div>
                        </div>
                        <div className={style.bio}>
                            {memberData.team.bio.map((par) => {
                                return <div className={style.bioPar}>{par.content}</div>;
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
