import { ArticleLabel } from "../ArticleLabel/ArticleLabel";
import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";
import { WheelControls } from "../WheelControls";

export const ArticleSlider = ({ articles }) => {
    const [sliderRef] = useKeenSlider(
        {
            breakpoints: {
                "(max-width : 820px)": {
                    slides: { perView: 2.7, spacing: 10 },
                },
                "(max-width : 428px)": {
                    slides: { perView: 1.8, spacing: 10 },
                },
            },
            slides: {
                perView: 2.2,
                spacing: 15,
            },
            rubberband: false,
        },
        [WheelControls]
    );

    return (
        <div ref={sliderRef} className={`keen-slider`}>
            {articles.map((elem, i) => {
                return <ArticleLabel article={elem} className={`keen-slider__slide number-slide${i}`} />;
            })}
        </div>
    );
};
