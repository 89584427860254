import ImageUrlBuilder from "@sanity/image-url";
import { useKeenSlider } from "keen-slider/react";
import { useEffect, useState } from "react";
import { client } from "../../client";
import { Footer } from "../../components/Footer/Footer";
import { SectionTitle } from "../../components/SectionTitle/SectionTitle";
import { AcqSliderElem } from "../../components/AcqSliderElem/AcqSliderElem";
import style from "./AcquisitionPage.module.css";
import "keen-slider/keen-slider.min.css";
import { WheelControls } from "../../components/WheelControls";

export const AcquisitionPage = () => {
    const [acqusitionPageData, setAcquisitionPageData] = useState();
    const imageBuilder = ImageUrlBuilder(client);

    useEffect(() => {
        const fetchData = () => {
            const query = `*[_type == "acquisitionPage"]`;
            client.fetch(query).then((result) => {
                setAcquisitionPageData(result[0]);
            });
        };
        fetchData();
    }, []);

    const [sliderRef] = useKeenSlider(
        {
            breakpoints: {
                "(max-width : 820px)": { slides: { perView: 3.5, spacing: 10 } },
                "(max-width : 428px)": { slides: { perView: 2.5, spacing: 10 } },
            },
            slides: {
                perView: 4.5,
                spacing: 15,
            },
        },
        [WheelControls]
    );

    return (
        <div className={style.moduleWrapper}>
            {acqusitionPageData && (
                <>
                    <div className={style.header}>
                        <div className={style.headerText}>{acqusitionPageData.welcomeText}</div>
                        <div
                            className={style.welcomeImage}
                            style={{
                                backgroundImage: `url(${imageBuilder.image(acqusitionPageData.welcomeImage)})`,
                            }}
                        ></div>
                    </div>
                    <div className={style.projectWrapper}>
                        <SectionTitle title="Project Criteria" />
                        <div className={style.projectText}>{acqusitionPageData.projectText}</div>
                        <div className={style.sliderWrapper}>
                            <div ref={sliderRef} className={`keen-slider ${style.projectSlider}`}>
                                {acqusitionPageData.criteriaSlider.map((elem) => {
                                    return <AcqSliderElem elem={elem} className="keen-slider__slide" />;
                                })}
                            </div>
                        </div>
                    </div>
                    <div className={style.marketsWrapper}>
                        <SectionTitle title="Target Markets" />
                        <div className={style.marketsText}>{acqusitionPageData.marketsText}</div>
                        <div className={style.mapWrapper}>
                            <div
                                className={style.map}
                                style={{
                                    backgroundImage: `url(${imageBuilder.image(acqusitionPageData.marketsMap)})`,
                                }}
                            ></div>
                        </div>
                    </div>
                    <div className={style.partnerWrapper}>
                        <SectionTitle title="Partner Criteria" />
                        <div className={style.partnerText}>{acqusitionPageData.partnerText}</div>
                        <div className={style.sliderWrapper}>
                            <div ref={sliderRef} className={`keen-slider ${style.projectSlider}`}>
                                {acqusitionPageData.partnerSlider.map((elem) => {
                                    return <AcqSliderElem elem={elem} className="keen-slider__slide" />;
                                })}
                            </div>
                        </div>
                    </div>
                    <Footer />
                </>
            )}
        </div>
    );
};
