import imageUrlBuilder from "@sanity/image-url";
import { Link } from "react-router-dom";
import { client } from "../../client";
import style from "./ProjectLabel.module.css";

export const ProjectLabel = ({ project, className }) => {
    const imageBuilder = imageUrlBuilder(client);

    return (
        <Link to={`/portfolio/${project.project.slug.current}`}>
            <div className={`${style.moduleWrapper} ${className}`}>
                <div className={style.imgWrapper}>
                    <img src={imageBuilder.image(project.project.image)} alt="" />
                </div>
                <h4 className={style.title}>{project.project.title}</h4>
                <div className={style.subtitle}>{project.project.subtitle}</div>
            </div>
        </Link>
    );
};
