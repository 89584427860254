import ImageUrlBuilder from "@sanity/image-url";
import { useEffect, useState } from "react";
import { client } from "../../client";
import { Footer } from "../../components/Footer/Footer";
import { ProjectLabel } from "../../components/ProjectLabel/ProjectLabel";
import { useCmsDataContext } from "../../context/CmsDataContext";
import style from "./PortfolioPage.module.css";

export const PortfolioPage = () => {
    const [portfolioPageData, setPortfolioPageData] = useState();
    const [activeFilter, setActiveFilter] = useState();
    const imageBuilder = ImageUrlBuilder(client);
    const { projectsData } = useCmsDataContext();
    const { citiesData } = useCmsDataContext();

    useEffect(() => {
        const fetchData = () => {
            const query = `*[_type == "portfolioPage"]`;
            client.fetch(query).then((result) => {
                setPortfolioPageData(result[0]);
            });
        };
        fetchData();
    }, []);

    return (
        <>
            {portfolioPageData && (
                <div className={style.moduleWrapper}>
                    <div
                        className={style.header}
                        style={{
                            backgroundImage: `url(${imageBuilder.image(portfolioPageData.welcomeImage)})`,
                        }}
                    >
                        <div className={style.headerOverlay}></div>
                        <div className={style.titleWrapper}>
                            <h1>{portfolioPageData.welcomeText}</h1>
                            <div className={style.filters}>
                                <ul>
                                    <li
                                        onClick={(e) => {
                                            setActiveFilter(null);
                                            if (document.querySelector(`.${style.active}`))
                                                document
                                                    .querySelector(`.${style.active}`)
                                                    .classList.remove(`${style.active}`);
                                            e.target.classList.add(`${style.active}`);
                                        }}
                                        className={`${style.active} grayOnHover`}
                                    >
                                        ALL
                                    </li>
                                    {citiesData &&
                                        citiesData.map((city) => {
                                            return (
                                                <li
                                                    key={city._id}
                                                    onClick={(e) => {
                                                        setActiveFilter(city._id);
                                                        if (document.querySelector(`.${style.active}`))
                                                            document
                                                                .querySelector(`.${style.active}`)
                                                                .classList.remove(`${style.active}`);
                                                        e.target.classList.add(`${style.active}`);
                                                    }}
                                                    className="grayOnHover"
                                                >
                                                    {city.cityName}
                                                    {" ("}

                                                    {projectsData &&
                                                        projectsData.reduce((total, elem) => {
                                                            return elem.project.city._ref === city._id
                                                                ? ++total
                                                                : total;
                                                        }, 0)}
                                                    {")"}
                                                </li>
                                            );
                                        })}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className={style.projectsGrid}>
                        {projectsData &&
                            projectsData.map((elem) => {
                                if (activeFilter === elem.project.city._ref) {
                                    return <ProjectLabel project={elem} key={elem.project.slug.current} />;
                                } else if (!activeFilter) {
                                    return <ProjectLabel project={elem} key={elem.project.slug.current} />;
                                }
                            })}
                    </div>
                </div>
            )}
            <Footer />
        </>
    );
};
