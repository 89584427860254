import ImageUrlBuilder from "@sanity/image-url/";
import { useEffect, useState } from "react";
import { ParallaxBanner } from "react-scroll-parallax";
import { client } from "../../client";
import { Footer } from "../../components/Footer/Footer";
import { MemberLabel } from "../../components/MemberLabel/MemberLabel";
import { StatsModule } from "../../components/StatsModule/StatsModule";
import { useCmsDataContext } from "../../context/CmsDataContext";
import style from "./ApproachPage.module.css";

export const ApproachPage = () => {
    const [approachPageData, setApproachPageData] = useState();
    const imageBuilder = ImageUrlBuilder(client);
    const { teamData } = useCmsDataContext();

    useEffect(() => {
        const fetchData = () => {
            const query = `*[_type == "approachPage"]`;
            client.fetch(query).then((result) => {
                setApproachPageData(result[0]);
            });
        };
        fetchData();
    }, []);

    return (
        <div className={style.moduleWrapper}>
            {approachPageData && (
                <>
                    <div className={style.header}>
                        <div className={style.headline}>
                            <h1>{approachPageData.welcomeText}</h1>
                        </div>
                        <div
                            className={style.imgWrapper}
                            style={{ backgroundImage: `url(${imageBuilder.image(approachPageData.welcomeImage)})` }}
                        ></div>
                    </div>
                    <div className={style.valuesModuleWrapper}>
                        <div className={style.valuesModule}>
                            <StatsModule
                                welcomeText={approachPageData.welcomeText}
                                firstValue={approachPageData.firstValue}
                                firstValueLabel={approachPageData.firstValueLabel}
                                secondValue={approachPageData.secondValue}
                                secondValueLabel={approachPageData.secondValueLabel}
                                thirdValue={approachPageData.thirdValue}
                                thirdValueLabel={approachPageData.thirdValueLabel}
                                fourthValue={approachPageData.fourthValue}
                                fourthValueLabel={approachPageData.fourthValueLabel}
                                fifthValue={approachPageData.fifthValue}
                                fifthValueLabel={approachPageData.fifthValueLabel}
                                sixthValue={approachPageData.sixthValue}
                                sixthValueLabel={approachPageData.sixthValueLabel}
                            />
                        </div>
                    </div>
                    <div className={style.mobileParallaxWrapper}>
                        <div
                            className={style.paraImage}
                            style={{ backgroundImage: `url(${imageBuilder.image(approachPageData.parallaxImage)})` }}
                        ></div>
                        <div className={style.headlineWrapper}>
                            <div className={style.parallaxHeadline}>{approachPageData.parallaxText}</div>
                        </div>
                    </div>
                    <div className={style.parallaxWrapper}>
                        <ParallaxBanner
                            layers={[
                                { image: imageBuilder.image(approachPageData.parallaxImage), speed: -50 },
                                { children: <div className={style.parallaxOverlay}></div> },
                                {
                                    children: (
                                        <div className={style.headlineWrapper}>
                                            <div className={style.parallaxHeadline}>
                                                {approachPageData.parallaxText}
                                            </div>
                                        </div>
                                    ),
                                },
                            ]}
                            style={{ height: `100lvh`, width: `100vw` }}
                        />
                    </div>
                    <div className={style.ceoModule}>
                        <div className={style.leftCol}>
                            <div className={style.nameWrapper}>
                                <div className={style.ceoName}>{approachPageData.ceoName}</div>
                                <div className={style.ceoPosition}>{approachPageData.ceoPositionText}</div>
                            </div>
                            <div className={style.ceoBio}>{approachPageData.ceoBio}</div>
                            <div className={style.contactWrapper}>
                                <div className={style.phone}>{approachPageData.ceoPhone}</div>
                                <a href={`mailto:${approachPageData.ceoEmail.toLowerCase()}`} className="grayOnHover">
                                    <div className={style.email}>{approachPageData.ceoEmail}</div>
                                </a>
                            </div>
                        </div>
                        <div className={style.rightCol}>
                            <div
                                className={style.ceoImgWrapper}
                                style={{ backgroundImage: `url(${imageBuilder.image(approachPageData.ceoPicture)})` }}
                            ></div>
                        </div>
                    </div>
                    <div className={style.teamMembers}>
                        <div className={style.teamTextWrapper}>
                            <h3>{approachPageData.teamText}</h3>
                        </div>
                        <div className={style.membersWrapper}>
                            {teamData &&
                                teamData.map((elem) => {
                                    return <MemberLabel memberData={elem} />;
                                })}
                        </div>
                    </div>
                    <Footer />
                </>
            )}
        </div>
    );
};
