import ImageUrlBuilder from "@sanity/image-url";
import clsx from "clsx";
import { useState } from "react";
import { client } from "../../client";
import { Button } from "../Button/Button";
import style from "./ArticleLabel.module.css";

export const ArticleLabel = ({ article, className }) => {
    const imageBuilder = ImageUrlBuilder(client);
    const [showPopup, setShowPopup] = useState(false);

    const handleArticleClick = (e) => {
        if (document.querySelector(`.${style.show}`)) {
            document.querySelector(`.${style.show}`).classList.remove(`${style.show}`);
        }
        setShowPopup((showPopup) => !showPopup);
    };

    return (
        <>
            <div className={`${style.moduleWrapper} ${className}`} onClick={handleArticleClick}>
                {article && (
                    <>
                        <div className={style.imgWrapper}>
                            <div
                                className={style.img}
                                style={{ backgroundImage: `url(${imageBuilder.image(article.image)})` }}
                            ></div>
                        </div>
                        <div className={style.title}>{article.title}</div>
                        <Button label="READ MORE" className={style.btn} />
                    </>
                )}
            </div>

            <div className={`${style.overlay} ${clsx(showPopup && style.show)}`}>
                {article && (
                    <>
                        <div
                            className={style.imgWrapper}
                            style={{ backgroundImage: `url(${imageBuilder.image(article.image)})` }}
                        ></div>
                        <div className={style.contentWrapper}>
                            <div className={style.close} onClick={() => setShowPopup(false)}></div>
                            <div className={style.descWrapper}>
                                <div className={style.titleWrapper}>
                                    <div className={style.title}>{article.title}</div>
                                    <div className={style.date}>{article.date}</div>
                                </div>
                                <div className={style.content}>
                                    {article.content.map((par) => {
                                        return <div className={style.bioPar}>{par.content}</div>;
                                    })}
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </>
    );
};
