import ImageUrlBuilder from "@sanity/image-url";
import { client } from "../../../../client";
import style from "./HighImage.module.css";

export const HighImage = (props) => {
    const imageBuilder = ImageUrlBuilder(client);

    return (
        <div className={style.moduleWrapper}>
            <div
                className={style.img}
                style={{ backgroundImage: `url(${imageBuilder.image(props.content.image)})` }}
            ></div>
        </div>
    );
};
