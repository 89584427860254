import ImageUrlBuilder from "@sanity/image-url";
import { client } from "../../client";
import style from "./AcqSliderElem.module.css";

export const AcqSliderElem = ({ elem, className }) => {
    const imageBuilder = ImageUrlBuilder(client);

    return (
        <div className={`${style.moduleWrapper} ${className}`}>
            <div className={style.title}>{elem.title}</div>
            <div className={style.infoWrapper}>
                <div className={style.icon} style={{ backgroundImage: `url(${imageBuilder.image(elem.icon)})` }}></div>
                {elem.descTitle ? <div className={style.descTitle}>{elem.descTitle}</div> : null}
                <div className={style.desc}>{elem.desc}</div>
            </div>
        </div>
    );
};
