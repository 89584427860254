import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { CmsDataProvider } from "./context/CmsDataContext";
import { ParallaxProvider } from "react-scroll-parallax";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <ParallaxProvider>
            <CmsDataProvider>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </CmsDataProvider>
        </ParallaxProvider>
    </React.StrictMode>
);
