import { Route, Routes } from "react-router-dom";
import { Menu } from "./pages/Menu/Menu";
import { HomePage } from "./pages/HomePage/HomePage";
import { ApproachPage } from "./pages/ApproachPage/ApproachPage";
import { PortfolioPage } from "./pages/PortfolioPage/PortfolioPage";
import { AcquisitionPage } from "./pages/AcquisitionPage/AcquisitionPage";
import { ContactPage } from "./pages/ContactPage/ContactPage";
import { Page404 } from "./pages/Page404/Page404";
import { Terms } from "./pages/Terms/Terms";
import { Privacy } from "./pages/Privacy/Privacy";
import { Cookie } from "./pages/Cookie/Cookie";
import { useCmsDataContext } from "./context/CmsDataContext";
import { Project } from "./components/Project/Project";

function App() {
    const { projectsData } = useCmsDataContext();

    return (
        <>
            <Menu />
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/approach" element={<ApproachPage />} />
                <Route path="/portfolio" element={<PortfolioPage />} />
                {projectsData &&
                    projectsData.map((elem) => {
                        return (
                            <Route
                                path={`/portfolio/${elem.project.slug.current}`}
                                element={<Project slug={elem.project.slug.current} />}
                            />
                        );
                    })}
                <Route path="/acquisition" element={<AcquisitionPage />} />
                <Route path="/contact" element={<ContactPage />} />
                <Route path="/cookie" element={<Cookie />} />
                <Route path="/privacy" element={<Privacy />} />
                <Route path="/terms" element={<Terms />} />
                <Route path="*" element={<Page404 />} />
            </Routes>
        </>
    );
}

export default App;
