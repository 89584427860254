import style from "./SectionTitle.module.css";

export const SectionTitle = ({ title, className }) => {
    return (
        <div className={`${style.moduleWrapper} ${className}`}>
            <h2>{title}</h2>
            <hr />
        </div>
    );
};
