import style from "./ValueLabel.module.css";

export const ValueLabel = ({ value, label }) => {
    return (
        <div className={style.moduleWrapper}>
            <div className={style.value}>{value}</div>
            <div className={style.label}>{label}</div>
            <hr />
        </div>
    );
};
