import { Link } from "react-router-dom";
import style from "./Button.module.css";

export const Button = ({ label, linkTo, href, className }) => {
    return (
        <>
            {linkTo ? (
                <Link to={linkTo}>
                    <div className={`${style.button} ${className ? className : ""}`}>{label}</div>
                </Link>
            ) : (
                <a href={href} target="_blank" rel="noreferrer">
                    <div className={`${style.button} ${className ? className : ""}`}>{label}</div>
                </a>
            )}
        </>
    );
};
