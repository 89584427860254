import { ValueLabel } from "../ValueLabel/ValueLabel";
import style from "./StatsModule.module.css";
import lw from "../../assets/images/svg/lw.svg";
import { Link } from "react-router-dom";

export const StatsModule = ({
    id,
    welcomeText,
    firstValue,
    firstValueLabel,
    secondValue,
    secondValueLabel,
    thirdValue,
    thirdValueLabel,
    fourthValue,
    fourthValueLabel,
    fifthValue,
    fifthValueLabel,
    sixthValue,
    sixthValueLabel,
}) => {
    return (
        <div className={style.moduleWrapper} id={id ? id : " "}>
            <div className={style.leftColumn}>
                <div className={style.logo}>
                    <img src={lw} alt="" />
                </div>
                <h3 className={style.desc}>{welcomeText}</h3>
                <Link to="/approach" className="grayOnHover">
                    <div className={style.learnMore}>LEARN MORE</div>
                </Link>
            </div>
            <div className={style.rightColumn}>
                <ValueLabel value={firstValue} label={firstValueLabel} />
                <ValueLabel value={secondValue} label={secondValueLabel} />
                <ValueLabel value={thirdValue} label={thirdValueLabel} />
                <ValueLabel value={fourthValue} label={fourthValueLabel} />
                <ValueLabel value={fifthValue} label={fifthValueLabel} />
                <ValueLabel value={sixthValue} label={sixthValueLabel} />
            </div>
        </div>
    );
};
