import style from "./HomePage.module.css";
import { StatsModule } from "../../components/StatsModule/StatsModule";
import { Footer } from "../../components/Footer/Footer";
import { useCmsDataContext } from "../../context/CmsDataContext";
import { useEffect, useState } from "react";
import { client } from "../../client";
import { ProjectLabel } from "../../components/ProjectLabel/ProjectLabel";
import { SectionTitle } from "../../components/SectionTitle/SectionTitle";
import "keen-slider/keen-slider.min.css";
import { Button } from "../../components/Button/Button";
import { ArticleSlider } from "../../components/ArticleSlider/ArticleSlider";

export const HomePage = () => {
    const { projectsData } = useCmsDataContext();
    const [homePageData, setHomePageData] = useState();
    const [bgVideoFilename, setBgVideoFilename] = useState("");
    const featuredIds = [];

    useEffect(() => {
        const fetchData = () => {
            const query = `*[_type == "homePage"]`;
            client.fetch(query).then((result) => {
                setHomePageData(result[0]);
                setBgVideoFilename(result[0].videoFilename);
            });
        };
        fetchData();
    }, []);

    return (
        <div className={style.moduleWrapper}>
            {homePageData && (
                <>
                    <div className={style.header}>
                        <div className={style.playerWrapper}>
                            <video className={style.player} autoPlay muted loop playsInline>
                                <source src={`assets/video/${bgVideoFilename}`} type="video/mp4" />
                            </video>
                        </div>
                        <div className={style.scroll}>
                            <a href="#stats">SCROLL TO EXPLORE</a>
                        </div>
                    </div>
                    <StatsModule
                        id="stats"
                        welcomeText={homePageData.welcomeText}
                        firstValue={homePageData.firstValue}
                        firstValueLabel={homePageData.firstValueLabel}
                        secondValue={homePageData.secondValue}
                        secondValueLabel={homePageData.secondValueLabel}
                        thirdValue={homePageData.thirdValue}
                        thirdValueLabel={homePageData.thirdValueLabel}
                        fourthValue={homePageData.fourthValue}
                        fourthValueLabel={homePageData.fourthValueLabel}
                        fifthValue={homePageData.fifthValue}
                        fifthValueLabel={homePageData.fifthValueLabel}
                        sixthValue={homePageData.sixthValue}
                        sixthValueLabel={homePageData.sixthValueLabel}
                    />
                    {projectsData && (
                        <div className={style.ourProjects}>
                            <SectionTitle title={"Our Projects"} className={style.title} />
                            <div className={style.featuredWrapper}>
                                {homePageData.homeProjects.map((elem) => {
                                    return projectsData.map((project) => {
                                        if (elem._ref === project._id) {
                                            featuredIds.push(project._id);
                                            return (
                                                <ProjectLabel
                                                    key={`project${1}`}
                                                    project={project}
                                                    className={style.projectLabel}
                                                />
                                            );
                                        }
                                    });
                                })}
                            </div>
                            <div className={style.projectsGrid}>
                                {projectsData &&
                                    projectsData.map((elem) => {
                                        if (!featuredIds.includes(elem._id)) {
                                            return <ProjectLabel project={elem} className={style.projectLabel} />;
                                        }
                                    })}
                            </div>
                        </div>
                    )}
                    <div className={style.ourApproach}>
                        <SectionTitle title={"Our Approach"} />
                        <div className={style.textWrapper}>
                            <div className={style.approachText}>{homePageData.approachText}</div>
                            <div className={style.buttonWrapper}>
                                <Button label="MEET OUR PEOPLE" linkTo="/approach" className={style.button} />
                            </div>
                        </div>
                    </div>
                    <div className={style.ecosystem}>
                        <SectionTitle title={"Ecosystem"} />
                        <div className={style.ecosystemContent}>
                            <div className={style.ecosystemDesc}>
                                {homePageData.firstEcosystemText}
                                <br />
                                <br />
                                {homePageData.secondEcosystemText}
                            </div>
                            <div className={style.articles}>
                                <ArticleSlider articles={homePageData.articles} />
                            </div>
                        </div>
                    </div>
                    <Footer />
                </>
            )}
        </div>
    );
};
