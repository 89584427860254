import style from "./FullWidthImage.module.css";
import ImageUrlBuilder from "@sanity/image-url";
import { client } from "../../../../client";

export const FullWidthImage = (props) => {
    const imageBuilder = ImageUrlBuilder(client);

    return (
        <div className={style.moduleWrapper}>
            <div
                className={style.img}
                style={{ backgroundImage: `url(${imageBuilder.image(props.content.image)})` }}
            ></div>
        </div>
    );
};
