import React from "react";
import { FiveImageGallery } from "./FiveImageGallery/FiveImageGallery";
import { FullWidthImage } from "./FullWidthImage/FullWidthImage";
import { HighImage } from "./HighImage/HighImage";
import { ImageSlider } from "./ImageSlider/ImageSlider";
import { TextModule } from "./TextModule/TextModule";
import { TwoParagraphsWithImage } from "./TwoParagraphsWithImage/TwoParagraphsWithImage";

export const Components = (content) => {
    const componentsList = {
        fiveImageGallery: FiveImageGallery,
        fullWidthImage: FullWidthImage,
        highImage: HighImage,
        imageSlider: ImageSlider,
        textModule: TextModule,
        twoParagraphsWithImage: TwoParagraphsWithImage,
    };

    return React.createElement(componentsList[content._type], {
        key: content._key,
        content: content,
    });
};
