import style from "./FiveImageGallery.module.css";
import ImageUrlBuilder from "@sanity/image-url";
import { client } from "../../../../client";

export const FiveImageGallery = (props) => {
    const imageBuilder = ImageUrlBuilder(client);

    return (
        <div className={style.moduleWrapper}>
            <div
                className={style.sqareImg1}
                style={{ backgroundImage: `url(${imageBuilder.image(props.content.firstSquareImage)})` }}
            ></div>
            <div
                className={style.sqareImg2}
                style={{ backgroundImage: `url(${imageBuilder.image(props.content.secondSquareImage)})` }}
            ></div>
            <div
                className={style.sqareImg3}
                style={{ backgroundImage: `url(${imageBuilder.image(props.content.thirdSquareImage)})` }}
            ></div>
            <div
                className={style.sqareImg4}
                style={{ backgroundImage: `url(${imageBuilder.image(props.content.fourthSquareImage)})` }}
            ></div>
            <div
                className={style.horizontalImg}
                style={{ backgroundImage: `url(${imageBuilder.image(props.content.horizontalImage)})` }}
            ></div>
        </div>
    );
};
