import ImageUrlBuilder from "@sanity/image-url";
import { useEffect, useState } from "react";
import { client } from "../../client";
import { Footer } from "../Footer/Footer";
import style from "./Project.module.css";
import { Components } from "./projectComponents/Components";

export const Project = ({ slug }) => {
    const [projectData, setProjectData] = useState();
    const imageBuilder = ImageUrlBuilder(client);

    useEffect(() => {
        const fetchData = () => {
            const query = `*[_type == "portfolio" && project.slug.current == "${slug}"]`;
            client.fetch(query).then((result) => {
                setProjectData(result[0]);
            });
        };
        fetchData();
    }, []);

    return (
        <div className={style.moduleWrapper}>
            {projectData && (
                <>
                    <div className={style.header}>
                        <div className={style.imageWrapper}>
                            <div
                                className={style.img}
                                style={{ backgroundImage: `url(${imageBuilder.image(projectData.project.image)})` }}
                            ></div>
                        </div>
                        <div className={style.detailsWrapper}>
                            <div className={style.projectName}>{projectData.project.title}</div>
                            <div className={style.roleWrapper}>
                                <div className={style.headline}>LIVWRK Role:</div>
                                <div className={style.role}>{projectData.project.role}</div>
                            </div>
                        </div>
                    </div>
                    <div className={style.contentWrapper}>
                        {projectData.projectContent.map((content) => Components(content))}
                    </div>
                    <Footer />
                </>
            )}
        </div>
    );
};
