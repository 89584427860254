import { useEffect, useState } from "react";
import { client } from "../../client";
import { SectionTitle } from "../SectionTitle/SectionTitle";
import { Button } from "../Button/Button";
import style from "./Footer.module.css";
import { Link } from "react-router-dom";

export const Footer = () => {
    const [footerData, setFooterData] = useState();
    const [windowWidth, setWindowWidth] = useState(window.innerHeight);

    useEffect(() => {
        const fetchData = () => {
            const query = `*[_type == "contactPage"]`;
            client.fetch(query).then((result) => {
                setFooterData(result[0]);
            });
        };
        fetchData();
    }, []);

    useEffect(() => {
        const resizeEvent = window.addEventListener("resize", () => {
            setWindowWidth(window.innerWidth);
        });

        return () => {
            window.removeEventListener("resize", resizeEvent);
        };
    }, []);

    return (
        <div className={style.moduleWrapper}>
            <SectionTitle title="Cultural Infrastructure" className={style.title} />
            {footerData && (
                <div className={style.gridWrapper}>
                    <div className={style.left}>
                        <div className={style.firstBlock}>{footerData.firstBlock}</div>
                        {windowWidth < 415 && <div className={style.mobileRightBlock}>{footerData.rightBlock}</div>}
                        <div className={style.secondBlock}>
                            {footerData.secondBlock}{" "}
                            {footerData.secondBlockLink.startsWith("http") ? (
                                <a
                                    href={footerData.secondBlockLink}
                                    target="_blank"
                                    rel="noreferrer"
                                    className={style.linkUnderline}
                                >
                                    {footerData.secondBlockLinkLabel}
                                </a>
                            ) : (
                                <Link to={footerData.secondBlockLink} className={style.linkUnderline}>
                                    {footerData.secondBlockLinkLabel}
                                </Link>
                            )}
                        </div>
                        <div className={style.thirdBlock}>{footerData.thirdBlock}</div>
                        <Button href={`mailto:${footerData.email}`} label="SEND EMAIL" className={style.button} />
                    </div>
                    <div className={style.bottomWrapper}>
                        <div className={style.policiesWrapper}>
                            <Link to="/cookie" className="grayOnHover">
                                COOKIE POLICY
                            </Link>
                            <Link to="/terms" className="grayOnHover">
                                TERMS & CONDITIONS
                            </Link>
                            <Link to="/privacy" className="grayOnHover">
                                PRIVACY POLICY
                            </Link>
                        </div>
                        <a
                            className={`${style.link247} grayOnHover`}
                            href="https://www.247studio.co"
                            target="_blank"
                            rel="noreferrer"
                        >
                            DESIGNED BY : 247 STUDIO
                        </a>
                    </div>
                    <div className={style.addressesWrapper}>
                        <div className={style.firstAddressWrapper}>
                            <div className={style.address}>{footerData.firstAddress}</div>
                            <a
                                href={footerData.firstAddressUrl}
                                className={`${style.addressUrl} ${style.linkUnderline}`}
                            >
                                GET DIRECTIONS
                            </a>
                        </div>
                        <div className={style.secondAddressWrapper}>
                            <div className={style.address}>{footerData.secondAddress}</div>
                            <a
                                href={footerData.secondAddressUrl}
                                className={`${style.addressUrl} ${style.linkUnderline}`}
                            >
                                GET DIRECTIONS
                            </a>
                        </div>
                        <div className={style.contactDetails}>
                            {footerData.phoneNumber}
                            <br />
                            <a href={`mailto:${footerData.email}`} className="grayOnHover">
                                {footerData.email}
                            </a>
                        </div>
                    </div>
                    <div className={style.rightWrapper}>
                        <div className={style.rightBlock}>{footerData.rightBlock}</div>
                        <div className={style.socialWrapper}>
                            <div className={style.firstSM}>
                                <a
                                    href={footerData.firstSMLink}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="grayOnHover"
                                >
                                    {footerData.firstSM}
                                </a>
                            </div>
                            <div className={style.secondSM}>
                                <a
                                    href={footerData.secondSMLink}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="grayOnHover"
                                >
                                    {footerData.secondSM}
                                </a>
                            </div>
                            <div className={style.thirdSM}>
                                <a
                                    href={footerData.thirdSMLink}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="grayOnHover"
                                >
                                    {footerData.thirdSM}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};
