import clsx from "clsx";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { client } from "../../client";
import style from "./Menu.module.css";

export const Menu = () => {
    const [contactData, setContactData] = useState();
    const [showMenu, setShowMenu] = useState(false);

    useEffect(() => {
        const fetchData = () => {
            const query = `*[_type == "contactPage"]`;
            client.fetch(query).then((result) => {
                setContactData(result[0]);
            });
        };
        fetchData();
    }, []);

    const handleMenuToggle = () => {
        setShowMenu((showMenu) => !showMenu);
    };

    return (
        <>
            <div className={style.overlay}>
                <Link to="/">
                    <div className={style.logo}>Cultural Infrastructure</div>
                </Link>
                <div className={style.buttons}>
                    <div className={style.menuButton} onClick={handleMenuToggle}>
                        MENU
                    </div>
                    <Link to="/contact">
                        <div className={style.contactButton}></div>
                    </Link>
                </div>
            </div>
            <div className={`${style.menu} ${clsx(showMenu && style.active)}`}>
                <div className={style.topBar}>
                    <div className={style.whLogo}></div>
                    <div className={style.buttons}>
                        <div
                            className={`${style.menuButton} grayOnHover`}
                            onClick={handleMenuToggle}
                            style={{ color: "#fff" }}
                        >
                            CLOSE
                        </div>
                        <Link to="/contact" onClick={handleMenuToggle}>
                            <div className={style.contactButton}></div>
                        </Link>
                    </div>
                </div>
                <div className={style.menuContent}>
                    <div className={style.addresses}>
                        {contactData && (
                            <>
                                <div className={style.addressWrapper}>
                                    <div className={style.firstAddress}>{contactData.firstAddress}</div>
                                    <div className={style.secondAddress}>{contactData.secondAddress}</div>
                                </div>
                                <div className={style.contactDetails}>
                                    <div className={style.phone}>{contactData.phoneNumber}</div>
                                    <a href={`mailto:${contactData.email}`} className="grayOnHover">
                                        <div className={style.email}>{contactData.email}</div>
                                    </a>
                                </div>
                            </>
                        )}
                    </div>
                    <div className={style.menuButtons}>
                        <Link to="/" onClick={handleMenuToggle} className="grayOnHover">
                            Home
                        </Link>
                        <Link to="/portfolio" onClick={handleMenuToggle} className="grayOnHover">
                            Projects
                        </Link>
                        <Link to="/approach" onClick={handleMenuToggle} className="grayOnHover">
                            Approach & Team
                        </Link>
                        <Link to="/acquisition" onClick={handleMenuToggle} className="grayOnHover">
                            Acquisitions Criteria
                        </Link>
                        <Link to="/contact" onClick={handleMenuToggle} className="grayOnHover">
                            Contact
                        </Link>
                    </div>
                </div>
            </div>
        </>
    );
};
